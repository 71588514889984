export var SearchList = [
  { labe: '编号', code: 'bh', type: 'input' }
  // { labe: '描述', code: 'ms', type: 'input' }
  // {
  //   labe: '类型',
  //   code: 'lx',
  //   type: 'select',
  //   option: [
  //     { label: '普通', value: '1' },
  //     { label: '专用', value: '2' },
  //     { label: 'AVAP', value: '3' }
  //   ]
  // }
]
export var SearchData = {
  bh: '',
  ms: '',
  lx: ''
}

export var tableField = [
  { type: 'input', selectType: 'selection', width: '' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '50%' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '90%' },
  // {
  //   label: '物料类别',
  //   code: 'materialType',
  //   type: 'function',
  //   width: '40%',
  //   handle: (index, data) => {
  //     return data.materialType === '1' ? '普通' : data.materialType === '2' ? '专用' : data.materialType === '3' ? 'AVAP' : ''
  //   }
  // },
  { label: '单位', code: 'units', type: 'input', width: '40%' },
  { label: '账面库存', code: 'inventory', type: 'input', width: '40%' },
  { label: '库位', code: 'storage', type: 'input', width: '40%' }
]

export var tableData = []
